import React, { useState } from "react";
import AdminSideBar from "./AdminSideBar";
import "../styles/BlogsManagement.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser } from "react-icons/fa6";
import { useEffect } from "react";
// import { API_BASE_URL } from "../../appConfig";
const BlogsManagement = () => {
  const addnavigate = useNavigate();
  const editnavigate = useNavigate();
  const [loadingBlog, setLoadingBlog] = useState(true);
  const [blogsData, setBlogsData] = useState([]);

  const addblog = () => {
    addnavigate("/admin/blogs-management/add-blog");
    window.scrollTo(0, 0);
  };

  // /admin/blogs-management/editblog

  const editblog = (blog) => {
    editnavigate("/admin/blogs-management/edit-blog", { state: { blog } });
    window.scrollTo(0, 0);
  };

  React.useEffect(() => {
    {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/getBlogs`)
        .then((responce) => {
          setBlogsData(responce.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoadingBlog(false);
        });
    }
  }, []);

  //search functionality
  const [livefilterBlog, setLivefilterBlog] = useState("");

  const blogTableAfterFilter = livefilterBlog
    ? blogsData.filter((row) => {
        const titleMatches =
          row.titleOfTheBlog &&
          row.titleOfTheBlog
            .toLowerCase()
            .includes(livefilterBlog.toLocaleLowerCase());

        return titleMatches;
      })
    : blogsData;

  //pegination
  //  pegination for Blog
  const [currentBlog, setCurrentBlog] = useState(1);
  const recordsPerPageBlog = 10;

  // Calculate the index range for the current page
  const startIndexBlog = (currentBlog - 1) * recordsPerPageBlog;
  const endIndexBlog = startIndexBlog + recordsPerPageBlog;

  // Slice the data based on the current page
  const visibleDataBlog = blogTableAfterFilter?.slice(
    startIndexBlog,
    endIndexBlog
  );

  // Calculate the total number of pages
  const totalPagesBlog = Math.ceil(
    blogTableAfterFilter?.length / recordsPerPageBlog
  );

  //for row serial number
  // totalPagesBlog-(totalPagesBlog-currentPagBlog)

  const forSerialNumber = recordsPerPageBlog * (currentBlog - 1);

  // Function to handle page change
  const handlePageChangeBlog = (newPageOne) => {
    setCurrentBlog(newPageOne);
  };


  useEffect(() => {
    setCurrentBlog(1);
  }, [livefilterBlog]);
  

  return (
    <div className="blogsManagement">
      <div className="">
        <div className="d-flex justify-content">
          <div className="col-2">
            <AdminSideBar />
          </div>
          {loadingBlog ? (
            <div className="col-10 py-3 px-3">
              <div className="text-center mt-3">
                {/* <div className="spinner-border text-secondary" role="status">
                  <div className="sr-only text-center">Loading...</div>
                </div> */}
                <div className="loader-container">
                  <div className="loader"></div>

                  <div>Please wait while we fetch the latest data for you</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-10 p-3 forBackground">
              <div className="">
                <div className="d-flex">
                  <BreadCrumb />
                  <div className="px-4 py-3">
                    <div className="d-flex justify-content-between">
                      <FaCircleUser size={25} className="mt-1 mx-2" />
                      <div className="align-middle mt-2">
                        {localStorage.getItem("adminName")}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className=" my-1" />
              </div>
              <div className=" mt-5">
                <div className="d-flex mt-3 justify-content forBackgroundTwo p-3 rounded-3">
                  <div className="col-4 align-middle">
                    <h5 className="pt-3">
                      Total Blogs Added : {blogsData.length}
                    </h5>
                  </div>
                  <div className="col-8">
                    <div className="d-flex justify-content-around">
                      <div className="col-6">
                        <div className="col-10 form-group has-search pt-2">
                          <span className="fa fa-search form-control-feedback  "></span>
                          <input
                            type="text"
                            class="form-control fs-6 rounded-pill bg-white "
                            placeholder="Search by Title"
                            onChange={(e) => setLivefilterBlog(e.target.value)}
                            // value={liveFilter}

                            style={{
                              fontWeight: "400",
                              border: "revert-layer", // Remove default border on focus
                              outline: "none", // Remove default outline on focus
                              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", // Add a custom box shadow on focus
                              borderWidth: "1px",
                            }}
                          />
                          {/* <span className="fa fa-search form-control-feedback "></span> */}
                        </div>
                      </div>
                      <div className="col-5 pt-2">
                        <button
                          type="button"
                          className="border-0 w-100 shadow-0 px-5 p-2 rounded  addSchlorshipButton"
                          onClick={() => addblog()}
                        >
                          + Add New Blog
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* search section end */}
              {blogsData.length > 0 ? (
                <>
                  <div className="">
                    <div className="forBackgroundTwo  px-3">
                      <div className="py-2">
                        {visibleDataBlog.length == 0 ? (
                          <div className="text-center">
                            No Search result found
                          </div>
                        ) : (
                          <table className="table rounded-3 table-hover shadow-lg custom-table">
                            <thead className="">
                              <tr className="text-center">
                                <td className="headingtext-color ForRowfont headingtext-color py-3">
                                  S.No.
                                </td>
                                <td className="headingtext-color py-3">
                                  Title
                                </td>
                                <td className="headingtext-color ForRowfont headingtext-color py-3">
                                  Author
                                </td>
                                <td className="headingtext-color ForRowfont headingtext-color py-3">
                                  Published date
                                </td>

                                <td className="headingtext-color ForRowfont headingtext-color py-3">
                                  Action
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {visibleDataBlog.map((blog, index) => {
                                return (
                                  <tr>
                                    <td className="align-middle text-center">
                                      {/* {index + 1} */}
                                      {forSerialNumber + (index + 1)}
                                    </td>
                                    <td className="align-middle text-center">
                                      {blog.titleOfTheBlog}
                                    </td>
                                    <td className="align-middle text-center">
                                      {" "}
                                      {blog.author}
                                    </td>
                                    <td className="align-middle text-center">
                                      {new Date(
                                        blog.createdAt
                                      ).toLocaleDateString("en-US", {
                                        month: "2-digit",
                                        day: "2-digit",
                                        year: "numeric",
                                      })}
                                    </td>
                                    <td className="align-middle text-center">
                                      <button
                                        type="button"
                                        className="btn btn-outline-primary editButton ForRowfont p-0  px-5"
                                        onClick={() => editblog(blog)}
                                      >
                                        Edit
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}

                        {/* Pagination controls */}
                        {totalPagesBlog > 1 ? (
                          <div className="d-flex justify-content-between">
                            <button
                              disabled={currentBlog === 1}
                              onClick={() =>
                                handlePageChangeBlog(currentBlog - 1)
                              }
                              className="custom-button fs-3"
                            >
                              ↼
                            </button>
                            <div>
                              Page {currentBlog} of {totalPagesBlog}
                            </div>
                            <button
                              disabled={currentBlog === totalPagesBlog}
                              onClick={() =>
                                handlePageChangeBlog(currentBlog + 1)
                              }
                              className="custom-button fs-3"
                            >
                              ⇀
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                //  <div class="bg-white mt-5">
                //   <h6 className="text-center p-3">There are currently no blogs.</h6>
                //   </div>
                <div
                  className="p-2 bg-white border rounded"
                  style={{
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="text-center mt-1">
                    <h5 className="text-center p-3">
                      There are currently no blogs.
                    </h5>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogsManagement;
