import React, { useState } from "react";
import AdminSideBar from "./AdminSideBar";
import "./../styles/ScholarshipManagment.css";
import axios from "axios";
import AddScholarship from "./AddScholarship";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { FaGraduationCap } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { CiSearch } from "react-icons/ci";
import BreadCrumb from "./BreadCrumb";
import { FaCircleUser } from "react-icons/fa6";
import { AiOutlineUpload } from "react-icons/ai";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import { formatAmount } from "../../hooks/useFormattedAmount";
import { format } from "date-fns";
import { useEffect } from "react";
// import { API_BASE_URL } from "../../appConfig";
const ScholarshipManagment = () => {
  //STATES TO SHOW COMPONENT
  const [currency, setCurrency] = useState('');
  //ONCLICK TO CHANGE BORER COLOR
  const [isClicked1, setIsClicked1] = useState(false);
  const [isClicked2, setIsClicked2] = useState(true);
  const [toastFile, setToastFile] = useState(false);
  const handleDivClickOne = () => {
    setIsClicked1(true);
    setIsClicked2(false);
  };
  const handleDivClick2 = () => {
    setIsClicked2(true);
    setIsClicked1(false);
  };

  const adminToken = localStorage.getItem("adminToken");
  const adminId = localStorage.getItem("adminId");
  const headers = {
    authorization: `bearer ${adminToken}`,
  };

  const [schlorShipData, setschlorShipData] = useState(null);
  const [loader, setLoader] = useState(true);
  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getAllScholarships/${adminId}`, {
        headers: headers,
      })
      .then((response) => {
        // console.log("axios responce " + response.data.scholarships);
        setCurrency(response.data.currency)
        setschlorShipData(response.data.scholarships);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  //FILTERS
  const [liveFilter, setLiveFilter] = useState("");
  const filteredScholarships = liveFilter
    ? schlorShipData?.filter((row) => {
        const countryMatches =
          row.scholarshipName &&
          row.scholarshipName.toLowerCase().includes(liveFilter.toLowerCase());
        return countryMatches;
      })
    : schlorShipData;


   useEffect(() => {
     setCurrentPageOther(1); // Reset page to 1 when search changes
   }, [liveFilter]);
   
   useEffect(()=>{
     setCurrentPage(1);
   },[liveFilter])


      console.log("filterscoof", filteredScholarships);

  //FILTER TO SHOW ACCORDING TO SCHLORSHIP PROVIDER
 const pathToStudyTable = filteredScholarships?.filter((scholarship) => {
   return scholarship.scholarshipProvider === "Path2Study";
 });

  //FILTER TO OTHER SCHLORSHIP
  const otherProviders = filteredScholarships?.filter((Otherscholarship) => {
    return Otherscholarship.scholarshipProvider !== "Path2Study";
  });

  //to navigate add schlorship page
  const navigateAdd = useNavigate();
  const addScholarships = () => {
    navigateAdd("add-scholarship", {
      state: {
        otherProvidersLength: otherProviders?.length || 0,
        pathToStudyTableLength: pathToStudyTable?.length || 0,
        schlorShipDataLength: schlorShipData?.length || 0,
        isClicked1,
        isClicked2,
      },
    });
  };

  const addtionalData = {
    TotalSchlorship: schlorShipData?.length,
    pathToStudySchlorship: pathToStudyTable?.length,
    OtherSchlorship: otherProviders?.length,
  };

  //to navigate edit schlorship
  const editnavigate = useNavigate();

  const handleEditClick = (tablerow) => {
    // Navigate to the edit page with the tablerow data
    editnavigate("edit-scholarship", { state: { tablerow, addtionalData } });
    window.scrollTo(0, 0);
  };

  //pegination other schlorship
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * recordsPerPage;
  const endIndex = startIndex + recordsPerPage;

  // Slice the data based on the current page
  const visibleData = otherProviders?.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(otherProviders?.length / recordsPerPage);
  const forSerialNumberOthers = recordsPerPage * (currentPage - 1);
  // Function to handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };



  //pegination for path2study
  const [currentPagpath2studye, setCurrentPageOther] = useState(1);
  const recordsPerPagepath2study = 10;

  // Calculate the index range for the current page
  const startIndexpath2study =
    (currentPagpath2studye - 1) * recordsPerPagepath2study;
  const endIndexpath2study = startIndexpath2study + recordsPerPagepath2study;

  // Slice the filtered data based on the current page
  const visibleDatapath2study = pathToStudyTable?.slice(
    startIndexpath2study,
    endIndexpath2study
  );
  // Calculate the total number of pages
  const totalPagespath2study = Math.ceil(
    pathToStudyTable?.length / recordsPerPagepath2study
  );
  
  const forSerialNumber =
    recordsPerPagepath2study * (currentPagpath2studye - 1);

  // Function to handle page change
  const handlePageChangePathToStudy = (newPageOne) => {
    setCurrentPageOther(newPageOne);
  };




  const forInputField = {
    fontWeight: "400",
    border: "revert-layer",
    outline: "none",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderWidth: "1px",
  };

  const checkRequiredKeys = (jsonData) => {
    const keysToCheck = ["Deadline", "amount", "scholarshipName", "startDate"];
    const keysNotPresent = keysToCheck.filter((key) => !(key in jsonData));
    return keysNotPresent;
  };

  

  // const checkRequiredKeysOther = (jsonData) => {
  //   const keysToCheck = ["Deadline","Scholarship Amount", "scholarshipName", "Start Date"];
  //   const keysNotPresent = keysToCheck.filter((key) => !(key in jsonData));
  //   return keysNotPresent;
  // };

  const checkRequiredKeysOther = (headerRow) => {
  const keysToCheck = ["Deadline", "Scholarship Amount", "Scholarship Name", "Start Date"];
  const keysNotPresent = keysToCheck.filter((key) => !headerRow.includes(key));
  return keysNotPresent;
};

const checkRequiredFieldsInRows = (jsonData, headerRow) => {
  const requiredFieldIndexes = {
    scholarshipName: headerRow.indexOf("Scholarship Name"),
    amount: headerRow.indexOf("Scholarship Amount"),
    startDate: headerRow.indexOf("Start Date"),
    deadline: headerRow.indexOf("Deadline")
  };

  const missingRows = [];

  jsonData.slice(1).forEach((row, index) => {
    let missingFields = [];

    // Check Scholarship Name
    if (!row[requiredFieldIndexes.scholarshipName]) {
      missingFields.push("Scholarship Name");
    }

    // Check Scholarship Amount
    if (!row[requiredFieldIndexes.amount]) {
      missingFields.push("Scholarship Amount");
    }

    // Check Start Date format (ensure it's a valid date)
    if (!row[requiredFieldIndexes.startDate] || isNaN(Date.parse(row[requiredFieldIndexes.startDate]))) {
      missingFields.push("Start Date (invalid format)");
    }

    // Check Deadline format (ensure it's a valid date)
    if (!row[requiredFieldIndexes.deadline] || isNaN(Date.parse(row[requiredFieldIndexes.deadline]))) {
      missingFields.push("Deadline (invalid format)");
    }

    // if (missingFields.length > 0) {
    //   missingRows.push({
    //     rowNumber: index + 2, // Row number starts from 2 (accounting for header)
    //     missingFields: missingFields.join(", ")
    //   });
    // }
  });

  return missingRows;
};


  // console.log("json",jsonData)

  const [isLoading, setisLoading] = useState(false);
  const [popUp, setPopUp] = useState(false);

  const uploadExcelSheet = (files) => {
    const file = files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        setisLoading(true);
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        console.log("JSON",jsonData)

        const formattedData = jsonData
          .slice(1) // Skip header row
          .filter(
            (row) =>
              row[1] && // Check if scholarshipName is defined
              row[2] && // Check if amount is defined
              row[5] && // Check if startDate is defined
              row[6] // Check if Deadline is defined
          )
          .map((row, index) => {
            const courseNames =
              typeof row[3] === "string"
                ? row[3].split(",").map((item) => item.trim())
                : [];
            const states =
              typeof row[11] === "string"
                ? row[11].split(",").map((item) => item.trim())
                : [];
            const genders =
              typeof row[12] === "string"
                ? row[12].split(",").map((item) => item.trim())
                : [];
            const citizenshipStatuses =
              typeof row[13] === "string"
                ? row[13].split(",").map((item) => item.trim())
                : [];
            const currentEducationalLevels =
              typeof row[14] === "string"
                ? row[14].split(",").map((item) => item.trim())
                : [];

            // Convert Excel date to JavaScript date object
            const excelDate = new Date((row[5] - 25569) * 86400 * 1000);
            const newDeadLine = new Date((row[6] - 25569) * 86400 * 1000);
            const formattedRow = {
              sr_no: row[0],
              scholarshipName: row[1],
              amount: row[2],
              courseName: courseNames,
              highschoolUniversity: row[4],
              // startDate: row[5],
              // startDate: new Date((row[5] - 25569) * 86400 * 1000),
              startDate: format(excelDate, "MM/dd/yyyy"),
              Deadline: format(newDeadLine, "MM/dd/yyyy"),
              // Deadline: row[6],
              description: row[7],
              minimumRequirements: row[8],
              availableToAll: row[9] === "Yes" ? true : false,
              country: row[10],
              state: states,
              gender: genders,
              citizenshipStatus: citizenshipStatuses,
              currentEducationalLevel: currentEducationalLevels,
              ethnicity: row[15],
              gpaRequired: row[16],
              scholarshipProvider: row[17],
            };
            return formattedRow;
          });

        const keyNotPresent2 = checkRequiredKeys(jsonData);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/admin/addScholarshipsInbulk/${adminId}`,
            formattedData,
            { headers: headers }
          )
          .then((response) => {
            setisLoading(false);
            const responseDataString = JSON.stringify(response.data.message);
            toast.success(`${responseDataString}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          })
          .catch((error) => {
            setisLoading(false);
            if (keyNotPresent2.length !== 0) {
              setPopUp(true);
            } else {
              toast.error("Error occurred while creating scholarships", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          });
      };

      reader.readAsArrayBuffer(file);
    } else {
      console.log("Please select a file.");
    }
  };

  const uploadOtherExcelSheet = (files) => {
    const file = files[0];
    if (file) {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        setisLoading(true); // Show loading spinner
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Convert Excel sheet to JSON
  
        const headerRow = jsonData[0]; // First row is the header
        console.log("Header Row:", headerRow);
  
        // Step 1: Check if required fields are present in the header
        const keyNotPresent = checkRequiredKeysOther(headerRow);
  
        if (keyNotPresent.length > 0) {
          // If any required keys are missing, show a popup and stop the upload
          setisLoading(false); // Stop loading
          setPopUp(true); // Show the popup
          toast.error(`Missing fields in header: ${keyNotPresent.join(", ")}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }
  
        // Step 2: Check rows under the headers for missing required fields and invalid date formats
        const missingRows = checkRequiredFieldsInRows(jsonData, headerRow);
  
        if (missingRows.length > 0) {
          // Show the popup with missing row information
          const errorMessages = missingRows.map(row => 
            `Row ${row.rowNumber}: ${row.missingFields}`
          ).join("\n");
  
          setisLoading(false); // Stop loading
          setPopUp(true); // Show the popup
          toast.error(`Errors in the following rows:\n${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }
  
        // Step 3: All required fields are present and valid, proceed with formatting the data
        const formattedData = jsonData
          .slice(1)
          .filter(
            (row) =>
              row[headerRow.indexOf("Scholarship Name")] && // Check if scholarshipName is defined
              row[headerRow.indexOf("Scholarship Amount")] && // Check if amount is defined
              row[headerRow.indexOf("Start Date")] && !isNaN(Date.parse(row[headerRow.indexOf("Start Date")])) && // Check if startDate is valid
              row[headerRow.indexOf("Deadline")] && !isNaN(Date.parse(row[headerRow.indexOf("Deadline")])) // Check if Deadline is valid
          )
          .map((row) => {
            const formattedRow = {
              sr_no: row[headerRow.indexOf("Sr No")],
              scholarshipName: row[headerRow.indexOf("Scholarship Name")],
              amount: row[headerRow.indexOf("Scholarship Amount")],
              highschoolUniversity: row[headerRow.indexOf("Highschool/University")],
              startDate: format(new Date(row[headerRow.indexOf("Start Date")]), "MM/dd/yyyy"),
              Deadline: format(new Date(row[headerRow.indexOf("Deadline")]), "MM/dd/yyyy"),
              availableToAll: row[headerRow.indexOf("Available To All")] === "Yes" ? true : false,
              country: row[headerRow.indexOf("Country")],
              ethnicity: row[headerRow.indexOf("Ethnicity")],
              gpaRequired: row[headerRow.indexOf("GPA Required")] || 0,
              scholarshipProvider: row[headerRow.indexOf("Scholarship Provider")],
              redirectionalUrl: row[headerRow.indexOf("Redirectional URL")],
            };
  
            return formattedRow;
          });
  
        // Step 4: Proceed with the upload if all checks passed
        if (formattedData.length > 0) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/admin/addScholarshipsInbulk/${adminId}`,
              formattedData,
              { headers: headers }
            )
            .then((response) => {
              setisLoading(false);
             
              const message = response.data.message
              console.log("formattedData",formattedData)
              toast.success(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            })
            .catch((error) => {
              setisLoading(false);
              toast.error("Error uploading data", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            });
        } else {
          setPopUp(true);
          setisLoading(false);
          toast.error("No valid data found for upload", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      };
  
      reader.readAsArrayBuffer(file); // Read file as an array buffer
    } else {
      console.log("Please select a file.");
    }
  };
  

  const handleDownloadClick = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/XLSheetAllScholarships?Path2Study=1`
    );
  };

  const handleOtherDownLoad = () => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/XLSheetAllScholarships?other=1`
    );
  };

  // modal-popup //

  const handleClose = () => {
    setPopUp(false);
  };

  return (
    <div className="ScholarshipManagment">
      <ToastContainer />
      {isLoading && (
        <div className="loader-container">
          <span className="loader"></span>
          <div>
            Please wait! Your scholarships are creating. It might take a moment
            to appear.
          </div>
        </div>
      )}
      <div className="">
        <div className="d-flex justify-content-between">
          <div className="col-2">
            <AdminSideBar />
          </div>
          {loader ? (
            <div className="col-10 py-3 px-3">
              <div className="text-center mt-3">
                <div className="loader-container">
                  <div className="loader"></div>

                  <div>Please wait while we fetch the latest data for you</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-10 py-3 px-3">
              <div className="">
                <div className="d-flex">
                  <BreadCrumb />
                  <div className="px-4 py-3">
                    <div className="d-flex justify-content-between">
                      <FaCircleUser size={25} className="mt-1 mx-2" />
                      <div className="align-middle mt-2">
                        {localStorage.getItem("adminName")}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className=" my-1" />
              </div>
              {/* cards */}
              <div className="d-flex justify-content-between mt-3">
                <div className="col-4 pe-3 ">
                  <div
                    className="rounded-3 p-3"
                    onClick={handleDivClick2}
                    style={{
                      border: `2px solid ${isClicked2 ? "#4572bf" : "white"}`,
                      backgroundColor: `${isClicked2 ? "#F6FDFF" : "white"}`,
                      padding: "10px",
                      cursor: "pointer",
                      height: "100%",
                    }}
                  >
                    <div className="d-flex justify-content-between  py-3 px-4">
                      <div className="">
                        <h5 className="mt-3">Path2study Scholarships</h5>
                      </div>

                      <div className="">
                        <h5 className="mt-3">{pathToStudyTable?.length}</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4 ">
                  <div
                    className="rounded-3 p-3"
                    onClick={handleDivClickOne}
                    style={{
                      border: `2px solid ${isClicked1 ? "#4572bf" : "white"}`,
                      backgroundColor: `${isClicked1 ? "#F6FDFF" : "white"}`,
                      padding: "10px",
                      cursor: "pointer",
                      height: "100%",
                    }}
                  >
                    <div className="d-flex justify-content-between py-3">
                      <div className="">
                        <h5 className="mt-3">Other Provider’s Scholarships</h5>
                      </div>

                      <div className="">
                        <h5 className="mt-3">{otherProviders?.length}</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  {isClicked2 ? (
                    <>
                      <div className="text-end ">
                        <label
                          htmlFor="fileInput"
                          className="p-1 pt-2 pb-2  text-center  cursor-pointer rounded border-0 w-75 btn btn-primary"
                        >
                          <AiOutlineUpload size={22} /> Upload Document
                        </label>
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          onChange={(e) => uploadExcelSheet(e.target.files)}

                          // onChange={(e) => handleFileChange(e)}
                        />
                      </div>
                      <div className="text-end mt-4 pt-2">
                        <button
                          onClick={handleDownloadClick}
                          className="rounded p-2 border border-primary text-primary bg-white w-75"
                        >
                          Download Excel Sheet
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-end ">
                        <label
                          htmlFor="fileInputOther"
                          className="p-1 pt-2 pb-2  text-center  cursor-pointer rounded border-0 w-75 btn btn-primary"
                        >
                          <AiOutlineUpload size={22} /> Upload Document
                        </label>
                        <input
                          type="file"
                          id="fileInputOther"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            uploadOtherExcelSheet(e.target.files)
                          }

                          // onChange={(e) => handleFileChange(e)}
                        />
                      </div>
                      <div className="text-end mt-4 pt-2">
                        <button
                          type="button"
                          className="rounded p-2 border border-primary text-primary bg-white w-75"
                          onClick={handleOtherDownLoad}
                        >
                          Download Excel Sheet
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/*----- TABLE PathStudySchlorships-------   */}
              {isClicked2 ? (
                <div
                  style={{ backgroundColor: "#F9FAFC" }}
                  className="border rounded-3 mt-3"
                >
                  <div className=" mt-3 p-2">
                    <div className="d-flex justify-content">
                      <div className="col-4 align-middle p-2">
                        <h5> Path2study Scholarships</h5>
                      </div>
                      <div className="col-8">
                        <div className="d-flex justify-content-around">
                          <div className="col-7">
                            <div className="col-10 form-group has-search">
                              <span className="fa fa-search form-control-feedback  "></span>
                              <input
                                type="text"
                                class="form-control fs-6 rounded-pill bg-white "
                                placeholder="Search by scholarship name"
                                value={liveFilter}
                                onChange={(e) => setLiveFilter(e.target.value)}
                                style={forInputField}
                              />
                              {/* <span className="fa fa-search form-control-feedback "></span> */}
                            </div>
                          </div>
                          <div className="col-4">
                            <button
                              type="button"
                              className="p-1 pt-2 pb-2 rounded border border-primary text-primary bg-white w-100 "
                              onClick={() => addScholarships()}
                            >
                              + Add New Scholarship
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" px-3 ">
                    {visibleDatapath2study?.length === 0 ? (
                      <p className="text-center">No search results found</p>
                    ) : (
                      <table className="table table-hover shadow-lg custom-table">
                        <thead className="">
                          <tr className="text-center">
                            <td className="headingtext-color ForRowfont py-3">
                              S.No.
                            </td>
                            <td
                              className="headingtext-color py-3"
                              // style={{ width: "20%" }}
                            >
                              Scholarship Name
                            </td>
                            <td className="headingtext-color ForRowfont py-3">
                              Amount
                            </td>

                            <td className="headingtext-color ForRowfont py-3">
                              Course Name
                            </td>

                            <td className="headingtext-color ForRowfont py-3">
                              No. of Applicants
                            </td>
                            <td className="headingtext-color ForRowfont py-3">
                              Status
                            </td>
                            <td className="headingtext-color ForRowfont py-3">
                              Action
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {visibleDatapath2study?.map((tablerow, index) => (
                            <tr key={index} className="text-center">
                              <td className="align-middle">
                                {/* {index + 1} */}
                                {/* {console.log("tablerow",tablerow)} */}

                                {forSerialNumber + (index + 1)}
                              </td>
                              <td className="align-middle ForRowfont">
                                {tablerow.scholarshipName}
                              </td>
                              <td className="align-middle ForRowfont">
                              {tablerow.currency ? tablerow.currency : "$"}{formatAmount(tablerow.amount)}
                              </td>

                              <td
                                className="align-middle"
                                style={{
                                  maxwidth: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {tablerow?.courseName?.length == 0
                                  ? "All"
                                  : tablerow?.courseName?.map((elemnt, i) => {
                                      if (
                                        i <
                                        tablerow?.courseName?.length - 1
                                      ) {
                                        return <div>{elemnt},</div>;
                                      }
                                      if (
                                        i ==
                                        tablerow?.courseName?.length - 1
                                      ) {
                                        return <div>{elemnt}</div>;
                                      }
                                    })}
                              </td>

                              <td className="align-middle ForRowfont">
                                {tablerow.appliedUsers.length}
                              </td>
                              <td className="align-middle">
                                <div className="d-flex align-items-center">
                                  <div className="col-3">
                                    <p
                                      className={` d-inline-block ${(() => {
                                        switch (tablerow.status) {
                                          case "live":
                                            return "text-primary";
                                          case "expired":
                                            return "text-danger";
                                          case "upcoming":
                                            return "text-warning";
                                          default:
                                            return "";
                                        }
                                      })()}`}
                                    >
                                      <GoDotFill
                                        className="mt-3 mb-1 me-2 ForRowfont"
                                        style={{ verticalAlign: "middle" }}
                                      />
                                    </p>
                                  </div>
                                  <div className="col-9 ForRowfont text-start">
                                    {tablerow.status?.charAt(0).toUpperCase() +
                                      tablerow.status?.slice(1)}
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle">
                                <button
                                  type="button"
                                  className="form-control btn btn-outline-primary editButton ForRowfont p-0 px-4"
                                  onClick={() => handleEditClick(tablerow)}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    {/* Pagination controls */}

                    {totalPagespath2study > 1 ? (
                      <div className="d-flex justify-content-between">
                        <button
                          disabled={currentPagpath2studye === 1}
                          onClick={() =>
                            handlePageChangePathToStudy(
                              currentPagpath2studye - 1
                            )
                          }
                          className="peginationButton custom-button"
                        >
                          <h3> ↼ </h3>
                        </button>
                        <div>
                          Page {currentPagpath2studye} of {totalPagespath2study}
                        </div>
                        <button
                          disabled={
                            currentPagpath2studye === totalPagespath2study
                          }
                          onClick={() =>
                            handlePageChangePathToStudy(
                              currentPagpath2studye + 1
                            )
                          }
                          className="custom-button"
                        >
                          <h3> ⇀</h3>
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                //FOR OTHER Other Provider’s Scholarships
                <div className="border rounded-3 mt-3">
                  <div className=" mt-3 p-2">
                    <div className="d-flex justify-content">
                      <div className="col-4  align-middle p-2">
                        <h5 className="">Other Scholarships</h5>
                      </div>
                      <div className="col-8">
                        <div className="d-flex justify-content-around">
                          <div className="col-7">
                            <div class="col-10 form-group has-search">
                              <span className="fa fa-search form-control-feedback  "></span>
                              <input
                                type="text"
                                className="form-control rounded-pill rounded-5"
                                placeholder="Search by scholarship name"
                                aria-label="Search"
                                aria-describedby=""
                                width={"100%"}
                                value={liveFilter}
                                onChange={(e) => setLiveFilter(e.target.value)}
                                style={{
                                  fontWeight: "400",
                                  border: "revert-layer",
                                  outline: "none",
                                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-4">
                            <button
                              type="button"
                              // className="p-1 pt-2 pb-2 rounded border border-primary text-primary bg-white w-75 "
                              className="p-1 pt-2 pb-2 rounded border border-primary text-primary bg-white w-100 "
                              onClick={() => addScholarships()}
                            >
                              + Add New Scholarship
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 ">
                    {visibleData?.length === 0 ? (
                      <p className="text-center">No search results found</p>
                    ) : (
                      <table className="table table-hover shadow-lg custom-table">
                        <thead className="">
                          <tr className="">
                            <td className="headingtext-color text-center py-3 ">
                              S.No.
                            </td>
                            <td className="headingtext-color text-center py-3 ">
                              Scholarship Name
                            </td>
                            <td className="headingtext-color text-center py-3 ">
                              Amount
                            </td>
                            {/* <td className="headingtext-color  text-center py-3">
                            Country
                          </td> */}
                            <td className="headingtext-color text-center py-3">
                              Sch. Provider
                            </td>
                            <td className="headingtext-color text-center py-3">
                              Course Name
                            </td>
                            <td className="headingtext-color text-center py-3">
                              Status
                            </td>
                            <td className="headingtext-color text-center py-3">
                              Action
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {visibleData?.map((tablerow, index) => (
                            <tr key={index} className="text-center">
                              {/* Render your table cells based on the filtered data */}
                              <td className="align-middle">
                                {/* {index + 1} */}
                                {forSerialNumberOthers + (index + 1)}
                              </td>
                              <td className="align-middle">
                                {tablerow.scholarshipName}
                              </td>
                              <td className="align-middle">
                              {currency ? currency : "$"}{formatAmount(tablerow.amount)}
                              </td>
                              {/* <td className="">{tablerow.Country}</td> */}
                              <td className="align-middle">
                                {tablerow.scholarshipProvider}
                              </td>
                              <td className="align-middle">
                                {tablerow?.courseName?.length == 0
                                  ? "All"
                                  : tablerow?.courseName?.map((elemnt, i) => {
                                      if (
                                        i <
                                        tablerow?.courseName?.length - 1
                                      ) {
                                        return <span>{elemnt},</span>;
                                      }
                                      if (
                                        i ==
                                        tablerow?.courseName?.length - 1
                                      ) {
                                        return <span>{elemnt}</span>;
                                      }
                                    })}
                              </td>

                              <td className="align-middle">
                                <div className="d-flex align-items-center">
                                  <div className="col-3">
                                    <p
                                      className={` d-inline-block ${(() => {
                                        switch (tablerow.status) {
                                          case "live":
                                            return "text-primary";
                                          case "expired":
                                            return "text-danger";
                                          case "upcoming":
                                            return "text-warning";
                                          default:
                                            return "";
                                        }
                                      })()}`}
                                    >
                                      <GoDotFill
                                        className="mt-3 mb-1 me-2"
                                        style={{ verticalAlign: "middle" }}
                                      />
                                    </p>
                                  </div>
                                  <div className="col-9 ForRowfontOther text-start">
                                    {/* {tablerow.status.charAt(0).toUpperCase() +
                                    tablerow.status.slice(1)} */}
                                    {tablerow && tablerow.status
                                      ? tablerow.status
                                          .charAt(0)
                                          .toUpperCase() +
                                        tablerow.status.slice(1)
                                      : "Status Unavailable"}
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle">
                                <button
                                  type="button"
                                  className="form-control btn btn-outline-primary editButton p-0 px-4"
                                  onClick={() => handleEditClick(tablerow)}
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    {/* Pagination controls */}
                    {totalPages > 1 ? (
                      <div className="d-flex justify-content-between">
                        <button
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                          className="custom-button fs-3"
                        >
                          ↼
                        </button>
                        <div>
                          Page {currentPage} of {totalPages}
                        </div>
                        <button
                          disabled={currentPage === totalPages}
                          onClick={() => handlePageChange(currentPage + 1)}
                          className="custom-button fs-3"
                        >
                          ⇀
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal show={popUp} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Missing Fields</Modal.Title>
        </Modal.Header>

        <p className="px-3">
          <p>
            Your excel file is missing some required fields. Please make sure
            that your file must has the following fields defined- <br></br>
            Scholarship name, Amount, Start date, and Deadline.
          </p>
        </p>

        <Modal.Footer>
          <button className="btn btn-outline-secondary " onClick={handleClose}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ScholarshipManagment;
